import React from 'react'
import Title from './Title'




const AboutUs = () => {
  return (
    
        <Title brand="About Us" images="https://preview.colorlib.com/theme/narosundar/assets/img/hero/hero1.jpg.webp"/>
  )
}

export default AboutUs
